
// 카카오톡 앱 키로 초기화합니다
if (window.Kakao) {
    window.Kakao.init('91080ec081f09b8cf1408c71fafe76db');
} else {
    console.error('Kakao SDK가 로드되지 않았습니다.');
}

export const shareKakao = (title, description, imageUrl, linkUrl) => {
    if (window.Kakao && window.Kakao.Link) {
        window.Kakao.Link.sendDefault({
            objectType: 'feed',
            content: {
                title: title,
                description: description,
                imageUrl: imageUrl,
                link: {
                    mobileWebUrl: linkUrl,
                    webUrl: linkUrl
                }
            },
            buttons: [
                {
                    title: '자세히 보기',
                    link: {
                        mobileWebUrl: linkUrl,
                        webUrl: linkUrl
                    }
                }
            ]
        });
    } else {
        console.error('Kakao SDK가 초기화되지 않았거나 Kakao.Link가 정의되지 않았습니다.');
    }
};
