import React, { useRef, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppContext  } from './context';
import Swal from 'sweetalert2';

const items = [
    { label: '뉴진스', path: '투표 > 연예인 > 가수' },
    { label: '나얼', path: '투표 > 연예인 > 가수' },
    { label: '고민시', path: '투표 > 연예인 > 가수' },
    { label: '고현정', path: '투표 > 연예인 > 가수' },
    { label: '김하늘', path: '투표 > 연예인 > 가수' },
    { label: '강하늘', path: '투표 > 연예인 > 가수' },
    { label: '강다니엘', path: '투표 > 연예인 > 가수' },
    { label: '이엘', path: '투표 > 연예인 > 가수' },
    { label: '유리', path: '투표 > 연예인 > 가수' },
    { label: '뉴트리아', path: '투표 > 연예인 > 가수' },
];

function SearchSlide() {
    const navigate = useNavigate();
    const location = useLocation();
    const suggestionsRef = useRef(null);
    const [query, setQuery] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [showSearchInput, setShowSearchInput] = useState(false);
    const { closeMenu } = useAppContext ();

    const handleChange = (event) => {
        const value = event.target.value;
        setQuery(value);

        if (value.length > 0) {
            const filteredSuggestions = items
                .filter(item =>
                    item.label.toLowerCase().includes(value.toLowerCase())
                )
                .slice(0, 5); // Limit suggestions to 5 items
            setSuggestions(filteredSuggestions);
        } else {
            setSuggestions([]);
        }
    };

    const handleSuggestionClick = (suggestion) => {
        setQuery(suggestion.label);
        setSuggestions([]);
        closeMenu();  // 메뉴 닫기 호출
        navigate(`/search?query=${encodeURIComponent(suggestion.label)}`);
    };

    const handleSearchClick = () => {
        if (query.trim() === '') {
            Swal.fire({
                icon: 'info',
                text: '검색어를 입력해주세요.',
                confirmButtonText: '확인'
            });
        } else {
            closeMenu();  // 메뉴 닫기 호출
            navigate(`/search?query=${encodeURIComponent(query)}`);
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearchClick();
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (suggestionsRef.current && !suggestionsRef.current.contains(event.target)) {
                setSuggestions([]);
                setShowSearchInput(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // 페이지 경로 변경 시 처리
    useEffect(() => {
        if (location.pathname !== '/search') {
            setQuery('');  // 검색 페이지가 아니면 검색어 초기화
            setSuggestions([]);  // 추천 리스트 초기화
            setShowSearchInput(false);  // 검색창 숨김
        }
    }, [location.pathname]);

    return (
        <div className="searchSlide">
            <div className="search">
                <input
                    name=""
                    type="text"
                    placeholder="검색어를 입력하세요"
                    value={query}
                    onChange={handleChange}
                    onKeyPress={handleKeyPress}
                    onFocus={() => setShowSearchInput(true)}
                />
                <img src="https://cdn.trend.rankify.best/dctrend/front/images/ico_search_gray.svg" alt="" onClick={handleSearchClick} />
            </div>
            {suggestions.length > 0 && showSearchInput && (
                <div className="suggestion" ref={suggestionsRef}>
                    {suggestions.map((suggestion, index) => (
                        <ul key={index} onClick={() => handleSuggestionClick(suggestion)}>
                            <li>{suggestion.label}</li>
                            <li>{suggestion.path}</li>
                        </ul>
                    ))}
                </div>
            )}
        </div>
    );
}

export default SearchSlide;
