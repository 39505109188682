import {Link} from "react-router-dom";
import StickyDefault from "../layout/stickyDefault";
import React from "react";

function RelationVote() {
    return (
        <>
            <StickyDefault/>
            <section className="contentsWrap">
                <div className="relationVote">
                    <div className="rvTitle"><span>'뉴진스'</span>연관 투표</div>
                    <div className="list">
                        <ul className="hoverImgPt">
                            <li className="thumb"><img src="https://i.namu.wiki/i/02Nd3r5_9XyQO8S9LMYQoVTCnsWO-NqYQf3N_PJYZmuxYOhJj5s9n9H66lozbQ9xB0zYl3GGRT3yzWiyVlTnWw.webp" alt=""/></li>
                            <li className="info">
                                <dl className="date">23.06.14 ~ 24.06.30</dl>
                                <dl className="title">아이돌 걸그룹 랭킹</dl>
                                <Link to="/vote/list"><dl className="btn">투표</dl></Link>
                            </li>
                        </ul>
                        <ul className="hoverImgPt">
                            <li className="thumb"><img src="https://i.namu.wiki/i/02Nd3r5_9XyQO8S9LMYQoVTCnsWO-NqYQf3N_PJYZmuxYOhJj5s9n9H66lozbQ9xB0zYl3GGRT3yzWiyVlTnWw.webp" alt=""/></li>
                            <li className="info">
                                <dl className="date">23.06.14 ~ 24.06.30</dl>
                                <dl className="title">아이돌 걸그룹 랭킹</dl>
                                <Link to="/vote/list"><dl className="btn">투표</dl></Link>
                            </li>
                        </ul>
                        <ul className="hoverImgPt">
                            <li className="thumb"><img src="https://i.namu.wiki/i/02Nd3r5_9XyQO8S9LMYQoVTCnsWO-NqYQf3N_PJYZmuxYOhJj5s9n9H66lozbQ9xB0zYl3GGRT3yzWiyVlTnWw.webp" alt=""/></li>
                            <li className="info">
                                <dl className="date">23.06.14 ~ 24.06.30</dl>
                                <dl className="title">아이돌 걸그룹 랭킹</dl>
                                <Link to="/vote/list"><dl className="btn">투표</dl></Link>
                            </li>
                        </ul>
                        <ul className="hoverImgPt">
                            <li className="thumb"><img src="https://i.namu.wiki/i/02Nd3r5_9XyQO8S9LMYQoVTCnsWO-NqYQf3N_PJYZmuxYOhJj5s9n9H66lozbQ9xB0zYl3GGRT3yzWiyVlTnWw.webp" alt=""/></li>
                            <li className="info">
                                <dl className="date">23.06.14 ~ 24.06.30</dl>
                                <dl className="title">아이돌 걸그룹 랭킹</dl>
                                <Link to="/vote/list"><dl className="btn">투표</dl></Link>
                            </li>
                        </ul>
                    </div>
                </div>

            </section>
        </>
    );
}

export default RelationVote;