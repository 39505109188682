import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import 'swiper/swiper-bundle.css';

function NewsMain() {
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/news/trend');
    }, [navigate]);

    return (
        <>
        </>
    );
}

export default NewsMain;
