// SortSelect.js
import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

function SortSelect({ options, selectedSort, onSortChange }) {
    const [isSortSelectVisible, setIsSortSelectVisible] = useState(false);
    const sortBoxRef = useRef(null);

    const toggleSortSelect = () => {
        setIsSortSelectVisible(!isSortSelectVisible);
    };

    const handleClickOutside = (event) => {
        if (sortBoxRef.current && !sortBoxRef.current.contains(event.target)) {
            setIsSortSelectVisible(false);
        }
    };

    const handleSortClick = (sortId) => {
        onSortChange(sortId); // Pass the ID of the selected sort option
        setIsSortSelectVisible(false);
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <div className="sortBox" ref={sortBoxRef}>
            <div className="sort" onClick={toggleSortSelect}>
                {options.find(option => option.id === selectedSort)?.label}
                <div className="arw s16"></div>
            </div>
            <div className="sortSelect" style={{ display: isSortSelectVisible ? 'block' : 'none' }}>
                {options.map(option => (
                    <ul
                        key={option.id}
                        className={selectedSort === option.id ? 'on' : ''}
                        onClick={() => handleSortClick(option.id)}
                    >
                        {option.label}
                        <div></div>
                    </ul>
                ))}
            </div>
        </div>
    );
}

SortSelect.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
    })).isRequired,
    selectedSort: PropTypes.string.isRequired,
    onSortChange: PropTypes.func.isRequired,
};

export default SortSelect;
