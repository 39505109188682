import React from 'react';
import { shareKakao } from '../js/module/kakaoShare';


const KakaoShareButton = ({ title, description, imageUrl, linkUrl }) => {
    const handleShare = () => {
        shareKakao(title, description, imageUrl, linkUrl);
    };

    return (
        <div onClick={handleShare} className="icoKakao"></div>
    );
};

export default KakaoShareButton;
