/**
 * HTML 문자열을 텍스트로 변환하고, 텍스트 길이를 제한합니다.
 * @param {string} htmlString - 변환할 HTML 문자열
 * @param {number} maxLength - 제한할 최대 텍스트 길이
 * @returns {string} - 제한된 길이의 텍스트
 */
export const htmlToText = (htmlString, maxLength = Infinity) => {
    // DOMParser를 사용하여 HTML을 파싱합니다.
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');

    // 문서의 body에서 텍스트만 추출합니다.
    let text = doc.body.textContent || '';

    // 텍스트 길이를 제한합니다.
    if (maxLength < Infinity && text.length > maxLength) {
        text = text.slice(0, maxLength) + '...'; // 필요에 따라 '...'을 추가할 수 있습니다.
    }

    return text;
};

export default htmlToText;
