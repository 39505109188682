import StickyDefault from "../layout/stickyDefault";
import React, { useEffect, useState } from "react";
import { Checkbox, Radio, Space } from 'antd';

function FeedBack() {
    useEffect(() => {
        document.body.style.backgroundColor = "#FAFAFC";
        return () => {
            document.body.style.backgroundColor = "#ffffff";
        };
    }, []);

    const [value, setValue] = useState(1);
    const [entityType, setEntityType] = useState("personal");
    const [sameAsReporter, setSameAsReporter] = useState(false);
    const [reporterInfo, setReporterInfo] = useState({
        name: "",
        email: "",
        phone: ""
    });

    const [rightsHolderInfo, setRightsHolderInfo] = useState({
        name: "",
        email: "",
        phone: ""
    });

    const [files, setFiles] = useState([]);

    const onChange = (e) => {
        setValue(e.target.value);
    };

    const onEntityTypeChange = (e) => {
        setEntityType(e.target.value);
    };

    const onSameAsReporterChange = (e) => {
        setSameAsReporter(e.target.checked);
        if (e.target.checked) {
            setRightsHolderInfo(reporterInfo);
        } else {
            setRightsHolderInfo({ name: "", email: "", phone: "" });
        }
    };

    useEffect(() => {
        if (sameAsReporter) {
            setRightsHolderInfo(reporterInfo);
        }
    }, [reporterInfo, sameAsReporter]);

    const handleFileClick = () => {
        document.querySelector('input[name="idDoc"]').click();
    };

    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        addFiles(selectedFiles);
    };

    const handleFileDrop = (e) => {
        e.preventDefault();
        const droppedFiles = Array.from(e.dataTransfer.files);
        addFiles(droppedFiles);
    };

    const addFiles = (selectedFiles) => {
        const validExtensions = ['jpg', 'jpeg', 'png', 'ppt', 'pptx', 'pdf'];
        const maxSize = 10 * 1024 * 1024; // 10MB
        const newFiles = [...files];

        for (let file of selectedFiles) {
            const fileExtension = file.name.split('.').pop().toLowerCase();
            if (newFiles.length >= 5) {
                alert('최대 5개의 파일만 첨부할 수 있습니다.');
                break;
            }
            if (!validExtensions.includes(fileExtension)) {
                alert(`지원하지 않는 파일 형식입니다: ${file.name}`);
                continue;
            }
            if (file.size > maxSize) {
                alert(`파일 크기가 너무 큽니다: ${file.name}`);
                continue;
            }
            newFiles.push(file);
        }

        setFiles(newFiles);
    };

    const handleFileDelete = (index) => {
        const newFiles = [...files];
        newFiles.splice(index, 1);
        setFiles(newFiles);
    };

    return (
        <>
            <StickyDefault />
            <section className="contentsWrap">
                <div className="feedBack">
                    <form>
                        <div className="title">피드백 센터</div>
                        <div className="box">
                            <div className="boxTitle">신고 사유 선택</div>
                            <div className="typeSelect">
                                <Radio.Group onChange={onChange} value={value}>
                                    <Space direction="vertical">
                                        <Radio value={1}>개인정보 침해</Radio>
                                        <Radio value={2}>저작권 침해</Radio>
                                        <Radio value={3}>초상권 침해</Radio>
                                        <Radio value={4}>상표권 침해</Radio>
                                        <Radio value={5}>불법 촬영 콘텐츠</Radio>
                                    </Space>
                                </Radio.Group>
                            </div>
                        </div>
                        <div className="box">
                            <div className="boxTitle">신고자 정보</div>
                            <div className="formBd">
                                <ul>
                                    <li className="stit">이름</li>
                                    <input
                                        name="name"
                                        type="text"
                                        placeholder="이름을 입력해주세요."
                                        value={reporterInfo.name}
                                        onChange={(e) =>
                                            setReporterInfo({ ...reporterInfo, name: e.target.value })
                                        }
                                    />
                                </ul>
                                <ul>
                                    <li className="stit">이메일</li>
                                    <input
                                        name="email"
                                        type="text"
                                        placeholder="이메일 주소를 입력해주세요."
                                        value={reporterInfo.email}
                                        onChange={(e) =>
                                            setReporterInfo({ ...reporterInfo, email: e.target.value })
                                        }
                                    />
                                </ul>
                                <ul>
                                    <li className="stit">전화번호</li>
                                    <input
                                        name="phone"
                                        type="text"
                                        placeholder="숫자만 입력해 주세요. (-제외)"
                                        value={reporterInfo.phone}
                                        onChange={(e) =>
                                            setReporterInfo({ ...reporterInfo, phone: e.target.value })
                                        }
                                    />
                                </ul>
                            </div>
                        </div>
                        <div className="box">
                            <div className="boxTitle">권리자 정보</div>
                            <div className="tab">
                                <ul>
                                    <input
                                        type="radio"
                                        id="type1"
                                        name="type"
                                        value="personal"
                                        checked={entityType === "personal"}
                                        onChange={onEntityTypeChange}
                                    />
                                    <label htmlFor="type1">개인</label>
                                </ul>
                                <ul>
                                    <input
                                        type="radio"
                                        id="type2"
                                        name="type"
                                        value="organization"
                                        checked={entityType === "organization"}
                                        onChange={onEntityTypeChange}
                                    />
                                    <label htmlFor="type2">단체<div>(기업 · 개인사업자 등)</div></label>
                                </ul>
                            </div>
                            <div className="copyInfo">
                                <Checkbox onChange={onSameAsReporterChange}>신고자와 동일하게 입력</Checkbox>
                            </div>
                            <div className="formBd">
                                <ul>
                                    <li className="stit">{entityType === "personal" ? "권리자 이름" : "단체명"}</li>
                                    <input
                                        name="nameRight"
                                        type="text"
                                        placeholder={entityType === "personal" ? "이름을 입력해주세요." : "단체명을 입력해주세요."}
                                        value={rightsHolderInfo.name}
                                        onChange={(e) =>
                                            setRightsHolderInfo({ ...rightsHolderInfo, name: e.target.value })
                                        }
                                    />
                                </ul>
                                <ul>
                                    <li className="stit">이메일</li>
                                    <input
                                        name="emailRight"
                                        type="text"
                                        placeholder="이메일 주소를 입력해주세요."
                                        value={rightsHolderInfo.email}
                                        onChange={(e) =>
                                            setRightsHolderInfo({ ...rightsHolderInfo, email: e.target.value })
                                        }
                                    />
                                </ul>
                                <ul>
                                    <li className="stit">{entityType === "personal" ? "권리자 전화번호" : "단체 전화번호"}</li>
                                    <input
                                        name="phoneRight"
                                        type="text"
                                        placeholder="숫자만 입력해 주세요. (-제외)"
                                        value={rightsHolderInfo.phone}
                                        onChange={(e) =>
                                            setRightsHolderInfo({ ...rightsHolderInfo, phone: e.target.value })
                                        }
                                    />
                                </ul>
                                <ul>
                                    <li className="stit">{entityType === "personal" ? "권리자 신분증" : "단체 증빙 서류"}</li>
                                    <div className="inpFile" onClick={handleFileClick}>
                                        <input
                                            name="idRight"
                                            type="text"
                                            placeholder={entityType === "personal" ? "신분증, 운전면허증 또는 여권 사진" : "단체 증빙 서류"}
                                            value={rightsHolderInfo.idRight || ""}
                                            disabled={true}
                                        />
                                        <div className="btnFile">파일 첨부</div>
                                    </div>
                                    <li className="infoText">파일 첨부: 최대 1개(jpg, jpeg, png 10MB 이하)</li>
                                    <input
                                        name="idDoc"
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={handleFileChange}
                                    />
                                </ul>
                            </div>
                        </div>
                        <div className="box">
                            <div className="boxTitle noBOt">파일첨부</div>
                            <div className="addFileText">신고 관련 파일 또는 저작물의 원본을 첨부해주세요.</div>
                            <div
                                className="addFileArea"
                                onClick={handleFileClick}
                                onDrop={handleFileDrop}
                                onDragOver={(e) => e.preventDefault()} // Allow drop by preventing default behavior
                            >
                                <ul><img src="https://cdn.trend.rankify.best/dctrend/front/images/ico_fileUpload.svg" alt="" />파일 첨부하기</ul>
                                <ul>파일 첨부<span>:</span> 최대 5개<div>(jpg, jpeg, png, ppt, pptx, pdf 10MB 이하)</div></ul>
                            </div>
                            <div className="addFileList">
                                {files.map((file, index) => (
                                    <ul key={index}>
                                        <li>{file.name}</li>
                                        <li><img src="https://cdn.trend.rankify.best/dctrend/front/images/btn_close_gray.svg" alt="삭제" onClick={() => handleFileDelete(index)} /></li>
                                    </ul>
                                ))}
                            </div>
                            <input
                                name="idDoc"
                                type="file"
                                style={{ display: 'none' }}
                                onChange={handleFileChange}
                                multiple // Allow multiple file selection
                            />
                        </div>
                        <div className="termsTitle">이용약관</div>
                        <div className="box noTop">
                            <div className="terms">
                                <ul>아래의 개인정보 수집 및 이용에 동의합니다. 개인정보의 수집 및 이용에 대한 동의를 거부하실 수 있으나 이 경우 신고 접수가 어려울 수 있습니다.</ul>
                                <ul className="part">
                                    <li>수집항목</li>
                                    <li>
                                        <dl><span>신고자 정보</span>신고자 정보 (필수) 이름, 이메일주소, 전화번호</dl>
                                        <dl><span>권리자 정보</span>권리자 정보: (필수) 이름, 전화번호, 신분증 사본, 단체번호</dl>
                                        <dl><span>신분증이란?</span>신분증이란? 주민등록증, 운전면허증, 여권</dl>
                                    </li>
                                </ul>
                                <ul className="part">
                                    <li>수집목적</li>
                                    <li>
                                        <dl>본인확인, 신고 접수/중복 확인 및 결과 회신, 분쟁조정을 위한 기록 보관</dl>
                                    </li>
                                </ul>
                                <ul className="part">
                                    <li>이용기간</li>
                                    <li>
                                        <dl>상기 수집항목은 전자상거래법 등 관련 법령에 따라 3년간 보관 후 파기합니다.</dl>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="agree">
                            <Checkbox id="agree">개인정보 수집 및 이용 동의</Checkbox>
                            <div className="req">필수</div>
                        </div>
                        <button className="btn" disabled={true}>제출하기</button>
                    </form>
                </div>
            </section>
        </>
    );
}

export default FeedBack;
