// src/atoms/AuthProvider.js
import React, { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { authState } from './authAtom';

const AuthProvider = ({ children }) => {
    const setAuthState = useSetRecoilState(authState);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const token = sessionStorage.getItem('authToken');
        const userJson = sessionStorage.getItem('user'); // 사용자 정보 JSON 문자열 가져오기
        const user = userJson ? JSON.parse(userJson) : null; // JSON 문자열을 객체로 변환

        if (token) {
            setAuthState({
                isAuthenticated: true,
                token: token,
                user: user, // 복원한 사용자 정보 설정
            });
        }
        setLoading(false);
    }, [setAuthState]);

    if (loading) {
        return <div></div>;
    }

    return children;
};

export default AuthProvider;
