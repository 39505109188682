import React from "react";


function LoadingSpinner() {

    return (
        <div className="loadingLayer">
            <div className="spinner"></div>
        </div>
    );
}

export default LoadingSpinner;
