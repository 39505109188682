import React, { useState, useEffect } from 'react';
import TopPcRight from './topPcRight';
import { Link } from 'react-router-dom';
import { useAppContext  } from '../../component/context';

function StickyDefault() {
    const [isSticky, setIsSticky] = useState(false);
    const { openMenu } = useAppContext ();

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 150) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <section id="sticky" className={`stickyWrap ${isSticky ? 'on' : ''}`} style={{ display: isSticky ? 'block' : 'none' }}>
            <div className="inBox">
                <div className="logo">
                    <Link to="/"><img src="https://cdn.trend.rankify.best/dctrend/front/images/logo.svg" alt="Logo" /></Link>
                </div>
                <TopPcRight />
                <div className="mo" onClick={openMenu}>
                    <img src="https://cdn.trend.rankify.best/dctrend/front/images/btn_menu.svg" alt="Menu" />
                </div>
            </div>
        </section>
    );
}

export default StickyDefault;
