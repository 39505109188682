import StickyDefault from "../layout/stickyDefault";
import React, { useEffect , useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import {Navigation} from "swiper/modules";
import ChartReport from "../../component/chartReport";
import { DatePicker, ConfigProvider } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import koKR from 'antd/es/locale/ko_KR';
dayjs.locale('ko');

function Report() {

    const [activeTab, setActiveTab] = useState('daily');
    const [disabled, setDisabled] = useState(true);

    const handleTabClick = (e) => {
        const tabCode = e.target.getAttribute('data-type');
        setActiveTab(tabCode);
        console.log(tabCode);

        if (tabCode === 'custom') {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    };

    useEffect(() => {
        document.body.style.backgroundColor = "#FAFAFC";
        return () => {
            document.body.style.backgroundColor = "#ffffff";
        };
    }, []);

    const dataPoints = [
        { x: '2024-06-29', y: 30000 },
        { x: '2024-06-30', y: 32000 },
        { x: '2024-07-01', y: 31000 },
        { x: '2024-07-02', y: 29000 },
        { x: '2024-07-03', y: 30000 },
        { x: '2024-07-04', y: 32000 },
        { x: '2024-07-05', y: 31000 },
        { x: '2024-07-06', y: 50000 },
        { x: '2024-07-07', y: 25000 },
        { x: '2024-07-08', y: 24000 },
        { x: '2024-07-09', y: 33000 },
        { x: '2024-07-10', y: 22000 },
        { x: '2024-07-11', y: 21000 },
        { x: '2024-07-12', y: 22000 }
    ];

    return (
        <>
            <StickyDefault/>
            <section className="contentsWrap">

                <div className="reportWrap">
                    <div className="top">
                        <ul className="navi">트렌드세터 연예인 랭킹</ul>
                        <ul className="title">
                            <li className="tit">'기은세' 트렌드 리포트</li>
                            <li className="rank">투표랭킹 <span>22위</span></li>
                        </ul>
                    </div>
                    <div className="topIndex">
                        <Swiper
                            slidesPerView={'auto'}
                            spaceBetween={8}
                            noSwiping={false}
                            noSwipingClass='swiper-slide'
                            breakpoints={{
                                480: {
                                    slidesPerView: 'auto',
                                    spaceBetween: 16,
                                    noSwiping: false,
                                    noSwipingClass: 'swiper-slide',
                                },
                                1200: {
                                    slidesPerView: 'auto',
                                    spaceBetween: 20,
                                    noSwiping: true,
                                    noSwipingClass: 'swiper-slide',
                                }
                            }}
                        >
                            <SwiperSlide>
                                <ul>
                                    <li>트렌드 지수</li>
                                    <li>52,130</li>
                                </ul>
                            </SwiperSlide>
                            <SwiperSlide>
                                <ul>
                                    <li>검색 지수</li>
                                    <li>46,719</li>
                                </ul>
                            </SwiperSlide>
                            <SwiperSlide>
                                <ul>
                                    <li>바이럴 지수</li>
                                    <li>686</li>
                                </ul>
                            </SwiperSlide>
                            <SwiperSlide>
                                <ul>
                                    <li>뉴스 지수</li>
                                    <li>56</li>
                                </ul>
                            </SwiperSlide>
                            <SwiperSlide>
                                <ul>
                                    <li>참여 지수</li>
                                    <li>4,669</li>
                                </ul>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                    <div className="trend">
                        <div className="left">
                            <div className="dbox chart">
                                <div className="stit">차트</div>
                                <div className="dateSelect">
                                    <div className="tab">
                                        <ul data-type="daily" className={activeTab === 'daily' ? 'on' : ''} onClick={handleTabClick}>일별</ul>
                                        <ul data-type="weekly" className={activeTab === 'weekly' ? 'on' : ''} onClick={handleTabClick}>주별</ul>
                                        <ul data-type="monthly" className={activeTab === 'monthly' ? 'on' : ''} onClick={handleTabClick}>월별</ul>
                                        <ul data-type="custom" className={activeTab === 'custom' ? 'on' : ''} onClick={handleTabClick}>직접입력</ul>
                                    </div>
                                    <div className={'picker ' + (disabled ? '' : 'on')}>
                                        <ConfigProvider locale={koKR} componentDisabled={disabled}>
                                            <DatePicker className="reportPicker" allowClear={false} format="YYYY.MM.DD" placeholder={'시작일'} />
                                            -
                                            <DatePicker className="reportPicker" allowClear={false} format="YYYY.MM.DD" placeholder={'종료일'} />
                                        </ConfigProvider>
                                    </div>
                                </div>
                                <div className="chartBox">
                                    <ChartReport dataPoints={dataPoints} />
                                </div>
                            </div>
                            <div className="dbox index search">
                                <div className="stit">검색 지수</div>
                                <div className="list">
                                    <ul>
                                        <li>모바일</li>
                                        <li>22,379</li>
                                    </ul>
                                    <ul>
                                        <li>PC</li>
                                        <li>24,343</li>
                                    </ul>
                                    <ul>
                                        <li>합계</li>
                                        <li>46,719</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="dbox index viral">
                                <div className="stit">바이럴 지수</div>
                                <div className="list">
                                    <ul>
                                        <li>블로그</li>
                                        <li>471</li>
                                    </ul>
                                    <ul>
                                        <li>까페</li>
                                        <li>215</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="dbox index news">
                                <div className="stit">뉴스 지수</div>
                                <div className="list">
                                    <ul>
                                        <li>뉴스</li>
                                        <li>56</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="dbox keyword">
                                <div className="stit">연관 검색어</div>
                                <div className="list">
                                    <ul>
                                        <li>1</li>
                                        <li>기은세 인스타그램</li>
                                    </ul>
                                    <ul>
                                        <li>2</li>
                                        <li>기은세 자라</li>
                                    </ul>
                                    <ul>
                                        <li>3</li>
                                        <li>기은세 도마</li>
                                    </ul>
                                    <ul>
                                        <li>4</li>
                                        <li>기은세집</li>
                                    </ul>
                                    <ul>
                                        <li>5</li>
                                        <li>기은세 열애</li>
                                    </ul>
                                    <ul>
                                        <li>6</li>
                                        <li>기은세 사주</li>
                                    </ul>
                                    <ul>
                                        <li>7</li>
                                        <li>기은세 옷</li>
                                    </ul>
                                    <ul>
                                        <li>8</li>
                                        <li>기은세 가방</li>
                                    </ul>
                                    <ul>
                                        <li>9</li>
                                        <li>기은세 파묘</li>
                                    </ul>
                                    <ul>
                                        <li>10</li>
                                        <li>기은세 귀걸이</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="dbox gender">
                                <div className="stit">관심 성별</div>
                                <div className="list">
                                    <ul>
                                        <li>남성</li>
                                        <li>20.34%</li>
                                    </ul>
                                    <ul>
                                        <li>여성</li>
                                        <li>79.66%</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="dbox age">
                                <div className="stit">관심 연령</div>
                                <div className="list">
                                    <ul>
                                        <li>10대</li>
                                        <li>11%</li>
                                    </ul>
                                    <ul>
                                        <li>20대</li>
                                        <li>28%</li>
                                    </ul>
                                    <ul>
                                        <li>30대</li>
                                        <li>29%</li>
                                    </ul>
                                    <ul>
                                        <li>40대</li>
                                        <li>22%</li>
                                    </ul>
                                    <ul>
                                        <li>50대</li>
                                        <li>10%</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="right">
                            <div className="dbox rankVote">
                                <div className="rvCate">연예인 > 기타</div>
                                <div className="stit">트렌드세터 연예인 랭킹</div>
                                <div className="rvDate">24.06.14 ~ 24.06.30</div>
                                <div className="list">
                                    <ul>
                                        <li className="rank">
                                            <dl>1</dl>
                                            <dl className="rankMark same">0</dl>
                                        </li>
                                        <li className="info">
                                            <dl className="thumb"><img src="https://www.harpersbazaar.co.kr/resources/online/online_image/2024/03/29/28bf24bd-6852-4616-951b-3456fb0c3807.jpg" alt=""/></dl>
                                            <dl className="txt">
                                                <dt className="cate">아이돌<div className="dot"></div>걸그룹</dt>
                                                <dt className="name">뉴진스</dt>
                                            </dl>
                                        </li>
                                        <li className="score">4,669 표</li>
                                    </ul>
                                    <ul>
                                        <li className="rank">
                                            <dl>2</dl>
                                            <dl className="rankMark up">2</dl>
                                        </li>
                                        <li className="info">
                                            <dl className="thumb"><img src="https://i.namu.wiki/i/4SsLvATlenq6ISktepJxXnGeiAXtrC6gv5XLTkLYpWRWHbfP_-u2D1VhUP7XHsIikW_QIgeH-OKdhu8HrMxihg.webp" alt=""/></dl>
                                            <dl className="txt">
                                                <dt className="cate">아이돌<div className="dot"></div>걸그룹</dt>
                                                <dt className="name">에스파</dt>
                                            </dl>
                                        </li>
                                        <li className="score">4,669 표</li>
                                    </ul>
                                    <ul>
                                        <li className="rank">
                                            <dl>3</dl>
                                            <dl className="rankMark same">0</dl>
                                        </li>
                                        <li className="info">
                                            <dl className="thumb"><img src="https://i.namu.wiki/i/dbYaykrNkUTz5RZOfjwQjU38vmA6zORYe_H60qH17Sl_PJ-oXcqvGPyuyJu6HKcDJuMqQdq--y6JPabC0QZzDA.webp" alt=""/></dl>
                                            <dl className="txt">
                                                <dt className="cate">아이돌<div className="dot"></div>걸그룹</dt>
                                                <dt className="name">IVE</dt>
                                            </dl>
                                        </li>
                                        <li className="score">4,669 표</li>
                                    </ul>
                                    <ul>
                                        <li className="rank">
                                            <dl>4</dl>
                                            <dl className="rankMark new">new</dl>
                                        </li>
                                        <li className="info">
                                            <dl className="thumb"><img src="https://phinf.wevpstatic.net/MjAyMzEwMTNfOTcg/MDAxNjk3MTc4NTMyOTA2.YcfBd9JEgr4AG6ae5CxXQlHFZoyz0Gsbsd28r9gViZsg.ufxCBuSFybk-1d8KlB7a3WHGoQNB-JnN_OlsJC2nmUcg.JPEG/bfb0f355-9e7d-4c83-a030-814c6fe53c7b.jpeg?type=w670" alt=""/></dl>
                                            <dl className="txt">
                                                <dt className="cate">아이돌<div className="dot"></div>걸그룹</dt>
                                                <dt className="name">QWER</dt>
                                            </dl>
                                        </li>
                                        <li className="score">4,669 표</li>
                                    </ul>
                                    <ul>
                                        <li className="rank">
                                            <dl>5</dl>
                                            <dl className="rankMark down">3</dl>
                                        </li>
                                        <li className="info">
                                            <dl className="thumb"><img src="https://i.namu.wiki/i/KjP897v1S9ZJsJcWefRGWNXEpZpmqjeAwP-VQOUA8cZRe8lSlfW6_gPoobenfltRC0Oa92dEN61t42prA50CbA.webp" alt=""/></dl>
                                            <dl className="txt">
                                                <dt className="cate">아이돌<div className="dot"></div>걸그룹</dt>
                                                <dt className="name">아일릿</dt>
                                            </dl>
                                        </li>
                                        <li className="score">4,669 표</li>
                                    </ul>
                                    <ul>
                                        <li className="rank">
                                            <dl>6</dl>
                                            <dl className="rankMark up">1</dl>
                                        </li>
                                        <li className="info">
                                            <dl className="thumb"><img src="https://nolae.eu/cdn/shop/articles/gi-dle-beginnt-2024-mit-dem-zweiten-studio-album-2-889229_1200x732.jpg?v=1704927358" alt=""/></dl>
                                            <dl className="txt">
                                                <dt className="cate">아이돌<div className="dot"></div>걸그룹</dt>
                                                <dt className="name">G-IDLE</dt>
                                            </dl>
                                        </li>
                                        <li className="score">4,669 표</li>
                                    </ul>
                                    <ul>
                                        <li className="rank">
                                            <dl>7</dl>
                                            <dl className="rankMark up">5</dl>
                                        </li>
                                        <li className="info">
                                            <dl className="thumb"><img src="https://pds.joongang.co.kr/news/component/htmlphoto_mmdata/202306/04/138bdfca-3e86-4c09-9632-d22df52a0484.jpg" alt=""/></dl>
                                            <dl className="txt">
                                                <dt className="cate">가수<div className="dot"></div>여성</dt>
                                                <dt className="name">아이유</dt>
                                            </dl>
                                        </li>
                                        <li className="score">4,669 표</li>
                                    </ul>
                                    <ul>
                                        <li className="rank">
                                            <dl>8</dl>
                                            <dl className="rankMark up">4</dl>
                                        </li>
                                        <li className="info">
                                            <dl className="thumb"><img src="https://i.namu.wiki/i/AF9SpVUNbbSFcOjRXIsyr29SxlZqhDX2Ui_dP04_VUYV98bC0t4bwkOs3Ec5FgNn6UcysEatLP5OYtsXTOzlkQ.webp" alt=""/></dl>
                                            <dl className="txt">
                                                <dt className="cate">가수<div className="dot"></div>남성</dt>
                                                <dt className="name">지코</dt>
                                            </dl>
                                        </li>
                                        <li className="score">4,669 표</li>
                                    </ul>
                                    <ul>
                                        <li className="rank">
                                            <dl>9</dl>
                                            <dl className="rankMark down">2</dl>
                                        </li>
                                        <li className="info">
                                            <dl className="thumb"><img src="https://img1.newsis.com/2023/01/06/NISI20230106_0001170846_web.jpg" alt=""/></dl>
                                            <dl className="txt">
                                                <dt className="cate">아이돌<div className="dot"></div>걸그룹</dt>
                                                <dt className="name">르세라핌</dt>
                                            </dl>
                                        </li>
                                        <li className="score">4,669 표</li>
                                    </ul>
                                    <ul>
                                        <li className="rank">
                                            <dl>10</dl>
                                            <dl className="rankMark same">0</dl>
                                        </li>
                                        <li className="info">
                                            <dl className="thumb"><img src="https://talkimg.imbc.com/TVianUpload/tvian/TViews/image/2022/11/29/479c1886-acb7-4416-963b-24baf1888c78.jpg" alt=""/></dl>
                                            <dl className="txt">
                                                <dt className="cate">아이돌<div className="dot"></div>걸그룹</dt>
                                                <dt className="name">카라</dt>
                                            </dl>
                                        </li>
                                        <li className="score">4,669 표</li>
                                    </ul>
                                    <ul>
                                        <li className="rank">
                                            <dl>21</dl>
                                            <dl className="rankMark same">0</dl>
                                        </li>
                                        <li className="info">
                                            <dl className="thumb"><img src="https://cdn.newsculture.press/news/photo/202309/533116_663468_2216.jpg" alt=""/></dl>
                                            <dl className="txt">
                                                <dt className="cate">배우<div className="dot"></div>여성</dt>
                                                <dt className="name">기은세</dt>
                                            </dl>
                                        </li>
                                        <li className="score">4,669 표</li>
                                    </ul>
                                </div>
                                <div className="btn">투표 한 번 더하기</div>
                            </div>
                        </div>
                    </div>
                    <div className="contents">
                        <div className="news">
                            <div className="stit">디시뉴스</div>
                            <div className="more">바로가기<div className="arw s16"></div></div>
                            <div className="list">
                                <ul>
                                    <li>이미지</li>
                                    <li>제목</li>
                                    <li>날짜</li>
                                </ul>
                                <ul>
                                    <li className="thumb"><img src="https://cdn.hankyung.com/photo/202103/03.21280006.1.jpg" alt=""/></li>
                                    <li className="title">기은세, 시크해도 청순해도 다 예뻐! 매력적인 민소매 원피스-청바지 패션기은세, 시크해도 청순해도 다 예뻐! 매력적인 민소매 원피스-청바지 패션</li>
                                    <li className="date">2021.06.11 14:31</li>
                                </ul>
                                <ul>
                                    <li className="thumb"><img src="https://www.news1.kr/_next/image?url=https%3A%2F%2Fi3n.news1.kr%2Fsystem%2Fphotos%2F2024%2F5%2F14%2F6649756%2Fhigh.jpg&w=1920&q=75" alt=""/></li>
                                    <li className="title">41세' 기은세, 모노키니 수영복 입고 뽐낸 글래머 몸매</li>
                                    <li className="date">2021.06.11 14:31</li>
                                </ul>
                                <ul>
                                    <li className="thumb"><img src="https://newsimg.hankookilbo.com/2018/11/02/201811020073733120_1.jpg" alt=""/></li>
                                    <li className="title">기은세, 결혼 후 더 주목 받은 ☆…배우→SNS 셀럽</li>
                                    <li className="date">2021.06.11 14:31</li>
                                </ul>
                                <ul>
                                    <li className="thumb"><img src="https://health.chosun.com/site/data/img_dir/2024/06/28/2024062802440_0.jpg" alt=""/></li>
                                    <li className="title">기은세, 다이어트 위해 '이 음식' 안 먹는 중이라 밝혀… 실제 효과 있을까?</li>
                                    <li className="date">2021.06.11 14:31</li>
                                </ul>
                                <ul>
                                    <li className="thumb"><img src="https://cooknchefnews.com/news/data/20190314/p1065623273958954_884_thum.jpg" alt=""/></li>
                                    <li className="title">기은세, 배우 보다 더 유명한 ′슈퍼 셀럽′...′수지 닮은 꼴′도 화제</li>
                                    <li className="date">2021.06.11 14:31</li>
                                </ul>
                            </div>
                        </div>
                        <div className="blogCafe">
                            <div className="stit">블로그 · 카페</div>
                            <div className="more">바로가기<div className="arw s16"></div></div>
                            <div className="list">
                                <ul className="blog">화인가 스캔들 출연진 김하늘 & 기은세 옷 패션! 원피스, 드레스, 귀걸이 가격</ul>
                                <ul className="cafe">국자라 기은세 벌룬 스커트 구입했어요</ul>
                                <ul className="blog">기은세 팩패드 두유패드 에센스 앰플 욕세럼 아이크림</ul>
                                <ul className="blog">40대 여자 연예인이 선택한 버버리 크로셰백 여름 가방 브랜드</ul>
                                <ul className="cafe">기은세 블랙 바디 수트</ul>
                                <ul className="cafe">자라 기은세 벌룬 스커트 팬츠!</ul>
                            </div>
                        </div>
                        <div className="youtube">
                            <div className="stit">유튜브</div>
                            <div className="more">바로가기<div className="arw s16"></div></div>
                            <div className="list">
                                <Swiper
                                    modules={[Navigation]} // Swiper 모듈 등록
                                    slidesPerView={'auto'}
                                    spaceBetween={8}
                                    breakpoints={{
                                        480: {
                                            slidesPerView: 'auto',
                                            spaceBetween: 8,
                                        },
                                        1200: {
                                            slidesPerView: 'auto',
                                            spaceBetween: 20,
                                        }
                                    }}
                                    navigation={{
                                        nextEl: '.swiper-button-next',
                                        prevEl: '.swiper-button-prev',
                                    }}
                                    className="youtubeRoll"
                                >
                                    <SwiperSlide><ul><img src="https://i.ytimg.com/vi/hna_Nf5678M/hq720.jpg" alt=""/></ul></SwiperSlide>
                                    <SwiperSlide><ul><img src="https://i.ytimg.com/vi/84IX3pVbteE/hq720.jpg" alt=""/></ul></SwiperSlide>
                                    <SwiperSlide><ul><img src="https://i.ytimg.com/vi/rr7f8OoiQz4/hq720.jpg" alt=""/></ul></SwiperSlide>
                                    <SwiperSlide><ul><img src="https://i.ytimg.com/vi/iG3d8amtXXA/hq720.jpg" alt=""/></ul></SwiperSlide>
                                    <SwiperSlide><ul><img src="https://i.ytimg.com/vi/_EoPmodYOZc/hq720.jpg" alt=""/></ul></SwiperSlide>
                                    <SwiperSlide><ul><img src="https://i.ytimg.com/vi/brFyTwKYOc4/hq720.jpg" alt=""/></ul></SwiperSlide>
                                    <SwiperSlide><ul><img src="https://i.ytimg.com/vi/50OGZOhowsE/hq720.jpg" alt=""/></ul></SwiperSlide>
                                    <SwiperSlide><ul><img src="https://i.ytimg.com/vi/UUTXOAUipHo/hq720.jpg" alt=""/></ul></SwiperSlide>
                                    <SwiperSlide><ul><img src="https://i.ytimg.com/vi/-J4BqOcmNt0/hq720.jpg" alt=""/></ul></SwiperSlide>
                                </Swiper>
                                <div className="swiper-button-next"></div>
                                <div className="swiper-button-prev"></div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>
    );
}

export default Report;