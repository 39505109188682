import React, { useRef, useEffect } from 'react';
import Chart from 'chart.js/auto';

const ChartMini = ({ data }) => {
    const chartRef = useRef(null);

    useEffect(() => {
        const canvas = chartRef.current;
        const ctx = canvas.getContext('2d');

        new Chart(ctx, {
            type: 'line',
            data: {
                labels: Array.from({ length: data.length }, (_, i) => i + 1),
                datasets: [{
                    data: data,
                    borderWidth: 1,
                    backgroundColor: '#FCE6E6',
                    borderColor: '#FF5656',
                    fill: true,
                    pointRadius: 0
                }]
            },
            options: {
                scales: {
                    x: {
                        display: false
                    },
                    y: {
                        display: false
                    }
                },
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        enabled: false // 툴팁 비활성화
                    },
                    datalabels: {
                        display: false // 데이터 레이블 비활성화 (Chart.js 2.x에서는 datalabels 플러그인 필요)
                    }
                },
                responsive: true,
                maintainAspectRatio: false
            }
        });

        // Cleanup function to destroy chart instance when component unmounts
        return () => {
            if (canvas) {
                const chartInstance = Chart.getChart(canvas);
                if (chartInstance) chartInstance.destroy();
            }
        };
    }, [data]); // data가 변경될 때마다 차트를 업데이트합니다.

    return <canvas ref={chartRef} />;
};

export default ChartMini;
