// src/atoms/authAtom.js
import { atom } from 'recoil';

export const authState = atom({
    key: 'authState',
    default: {
        isAuthenticated: false,
        token: null,
        user: null, // 사용자 정보를 추가
    },
});
