import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

function useScrollRestoration() {
    const { pathname } = useLocation();

    useEffect(() => {
        // 페이지 이동 시 스크롤을 상단으로 이동
        window.scrollTo(0, 0);
    }, [pathname]);

    // 추가적인 로직이 필요한 경우 여기에 작성

}

export default useScrollRestoration;
