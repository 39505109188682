import React, { useState, useEffect } from 'react';
import TopPcRight from './topPcRight';
import { Link } from "react-router-dom";

function StickyNewsDetail({ title, onShareClick, onFontClick }) {
    const [isSticky, setIsSticky] = useState(false);
    const [scrollWidth, setScrollWidth] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            // Check if the sticky condition is met
            if (window.scrollY > 150) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }

            // Calculate the scroll progress
            const scrollTop = window.scrollY;
            const docHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
            const scrollPercent = (scrollTop / docHeight) * 100;
            setScrollWidth(scrollPercent);
        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <section id="sticky" className={`stickyArticleWrap ${isSticky ? 'on' : ''}`} style={{ display: isSticky ? 'block' : 'none' }}>
            <div className="inBox">
                <div className="logo">
                    <Link to="/"><img src="https://cdn.trend.rankify.best/dctrend/front/images/logo.svg" alt="Logo"/></Link>
                </div>
                <div className="articleTitle">{title}</div>
                <TopPcRight/>
                <div className="mo">
                    <img src="https://cdn.trend.rankify.best/dctrend/front/images/btn_share.svg" onClick={onShareClick} alt=""/>
                    <img src="https://cdn.trend.rankify.best/dctrend/front/images/btn_fontsize.svg" onClick={onFontClick} alt=""/>
                </div>
            </div>
            <div className="scrollProcess" style={{ width: `${scrollWidth}%` }}></div>
        </section>
    );
}

export default StickyNewsDetail;
