import React, {useEffect} from "react";
import StickyDefault from "../layout/stickyDefault";
import {Link, useNavigate} from "react-router-dom";

function Another() {
    const navigate = useNavigate();

    useEffect(() => {
        document.body.style.backgroundColor = "#FAFAFC";
        return () => {
            document.body.style.backgroundColor = "#ffffff";
        };
    }, []);

    return (
        <>
            <StickyDefault/>
            <section className="contentsWrap">

                <div className="voteWrap">
                    <div className="box">
                        <div className="targetVote">
                            <ul className="thumb"><img src="https://d2k5miyk6y5zf0.cloudfront.net/article/MYH/20240702/MYH20240702024800641.jpg" alt=""/></ul>
                            <ul className="info">
                                <li className="title">트렌드세터 연예인 랭킹</li>
                                <li className="date">24.06.14 ~ 24.06.30</li>
                            </ul>
                        </div>
                    </div>
                    <div className="box mgt16">
                        <div className="anotherSearch">
                            <div className="title">선호하는 대상이 없나요?</div>
                            <div className="txt">선호하는 대상을 검색해 주세요</div>
                            <div className="inpBox">
                                <div className="inp">
                                    <div className="searchKeyword">
                                        <ul>한예슬</ul>
                                        <ul><img src="https://cdn.trend.rankify.best/dctrend/front/images/btn_close_gray.svg" alt=""/></ul>
                                    </div>
                                    <img src="https://cdn.trend.rankify.best/dctrend/front/images/ico_search_gray.svg" alt=""/>
                                    <input name="" type="text" placeholder="검색어를 입력하세요" style={{display:'none'}}/>
                                </div>
                                <div className="btn">검색</div>
                            </div>
                        </div>
                        <div className="anotherResult">
                            <div className="item">
                                <ul className="thumb"><img src="https://i.namu.wiki/i/EBUVfZZocLUdYFA9tfEtDVGxkLSs_Vbt0h_ydhG4G7g0ur6pawUOPW7q4l5AS2luEd_5WoWqpW1UqWd1cFAwQA.webp" alt=""/></ul>
                                <ul className="info">
                                    <li className="cate">배우</li>
                                    <li className="name">한예슬</li>
                                </ul>
                                <ul className="check"></ul>
                            </div>
                            <Link to="/vote/do"><div className="btn">투표하기</div></Link>
                        </div>
                        <div className="anotherResultNone" style={{display:'none'}}>
                            <ul>검색 결과가 없습니다.</ul>
                            <ul>투표와 관련이 없는 검색어 입니다.<br/>다른 키워드를 입력해 보세요.</ul>
                        </div>
                    </div>
                    <div className="tgnc"><div className="backVote" onClick={() => {
                        navigate(-1); // -1을 전달하여 이전 페이지로 이동
                    }}>투표화면으로 돌아가기</div></div>
                </div>

            </section>
        </>
    );
}

export default Another;