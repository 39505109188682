import React, { useEffect, useRef } from "react";
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

const getChartOptions = (isSmallScreen) => ({
    indexAxis: isSmallScreen ? 'y' : 'x',
    plugins: {
        legend: {
            display: false
        },
        tooltip: {
            enabled: false, // 기본 툴팁 비활성화
            external: function(context) {
                const { chart, tooltip } = context;
                let tooltipEl = document.getElementById('chartjs-tooltip');

                // 툴팁 요소가 없다면 생성
                if (!tooltipEl) {
                    tooltipEl = document.createElement('div');
                    tooltipEl.id = 'chartjs-tooltip';
                    document.body.appendChild(tooltipEl);
                }

                // 툴팁이 보이지 않으면 숨김 처리
                if (tooltip.opacity === 0) {
                    tooltipEl.style.opacity = 0;
                    return;
                }

                // HTML 템플릿 작성
                let innerHtml = `<div class="chartTooltip1">
                                            <ul>${tooltip.title[0]}</ul>
                                            <ul>${tooltip.body[0].lines[0]}</ul>
                                            <div class="botArw"></div>
                                         </div>`;

                tooltipEl.innerHTML = innerHtml;

                // 툴팁의 위치를 계산
                const position = chart.canvas.getBoundingClientRect();
                const tooltipWidth = tooltipEl.offsetWidth;
                const tooltipHeight = tooltipEl.offsetHeight;

                tooltipEl.style.opacity = 1;
                tooltipEl.style.position = 'absolute';

                // 툴팁이 마우스 포인터 위에 위치하도록 설정
                tooltipEl.style.left = position.left + window.pageXOffset + tooltip.caretX - tooltipWidth / 2 + 'px';
                tooltipEl.style.top = position.top + window.pageYOffset + tooltip.caretY - tooltipHeight - 10 + 'px'; // 10px 마진 추가

                tooltipEl.style.pointerEvents = 'none';
            }
        },
        datalabels: {
            color: '#2D65F2',
            display: isSmallScreen,
            anchor: isSmallScreen ? 'end' : 'center',
            align: isSmallScreen ? 'top' : 'end',
            formatter: (value) => value.toLocaleString(),
            font: {
                size: 11,
                family: 'Pretendard',
                weight: '600'
            }
        }
    },
    scales: {
        y: {
            beginAtZero: true,
            ticks: {
                color: isSmallScreen ? '#5C667B' : '#B4C0D3',
                font: {
                    size: 12,
                    family: 'Pretendard',
                    weight: isSmallScreen ? 600 : 400,
                }
            },
            grid: {
                color: '#EAEDF4',
                display: !isSmallScreen
            }
        },
        x: {
            ticks: {
                autoSkip: true,
                maxTicksLimit: isSmallScreen ? 2 : 10,
                color: isSmallScreen ? '#B4C0D3' : '#5C667B',
                font: {
                    size: isSmallScreen ? 12 : 14,
                    family: 'Pretendard',
                    weight: isSmallScreen ? 400 : 600,
                }
            },
            grid: {
                display: false
            }
        }
    },
    responsive: true,
    maintainAspectRatio: false,
    barThickness: isSmallScreen ? 10 : 16
});

const ChartRank = ({ dataPoints }) => {
    const chartRef = useRef(null);
    const [isSmallScreen, setIsSmallScreen] = React.useState(window.innerWidth < 480);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 480);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (chartRef.current) {
            chartRef.current.update();
        }
    }, [dataPoints, isSmallScreen]);

    return (
        <div style={{ position: 'relative', height: '100%' }}>
            <Bar
                ref={chartRef}
                data={dataPoints}
                options={getChartOptions(isSmallScreen)}
                plugins={[ChartDataLabels]}
            />
        </div>
    );
};

export default ChartRank;
