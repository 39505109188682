import React, { useState, useEffect } from 'react';
import StickyDefault from "../layout/stickyDefault";
import { Checkbox } from 'antd';
import {Link, useNavigate} from "react-router-dom";

function DoVote() {
    const navigate = useNavigate();
    const [ticketCount, setTicketCount] = useState(1); // 사용 투표권 상태 관리
    const [alwaysUseAll, setAlwaysUseAll] = useState(false); // 항상 전체사용 체크박스 상태 관리
    const [voteCompleted, setVoteCompleted] = useState(false); // 투표 완료 상태 관리
    const availableTickets = 5; // 사용 가능한 총 투표권 (여기서 임의로 5개로 설정)

    useEffect(() => {
        document.body.style.backgroundColor = "#FAFAFC";
        return () => {
            document.body.style.backgroundColor = "#ffffff";
        };
    }, []);

    useEffect(() => {
        if (alwaysUseAll) {
            setTicketCount(availableTickets);
        }
    }, [alwaysUseAll]);

    const handleTicketCountChange = (e) => {
        setTicketCount(e.target.value);
    };

    const handleAlwaysUseAllChange = (e) => {
        setAlwaysUseAll(e.target.checked);
    };

    const handleVote = () => {
        // 투표 완료 상태로 변경
        setVoteCompleted(true);
    };

    return (
        <>
            <StickyDefault/>
            <section className="contentsWrap">
                <div className="voteWrap">
                    <div className="box">
                        <div className="voteInfo">
                            <div className="category">연예인<div className="arw s16"></div>기타</div>
                            <div className="title">트렌드세터 연예인 랭킹</div>
                            <div className="date">24.06.14 ~ 24.06.30</div>
                            <div className="item">
                                <ul className="thumb"><img src="https://i.namu.wiki/i/BTFUZ_0TugZ15vrrsRKMVEJoqKGOF_jD-aw5sGeZZJnd3OIGWCcoVzaJ3YHBusb_msDaT_fnWqa5KR6APsT-uQ.webp" alt=""/></ul>
                                <ul>
                                    <li className="cate">배우</li>
                                    <li className="name">김고은</li>
                                </ul>
                            </div>
                        </div>
                        {!voteCompleted ? (
                            <div className="ticket">
                                <div className="myTicket">
                                    <ul className="my">보유 투표권 <span>{availableTickets}개</span></ul>
                                    <ul className="btn">투표권 구매<div className="arw s16"></div></ul>
                                </div>
                                <div className="ticketUse">
                                    <ul className="inpBox">
                                        <li className="tit">사용 투표권</li>
                                        <li className="inp"><input name="ticketCount" type="number" value={ticketCount} onChange={handleTicketCountChange} /></li>
                                        <li className="unit">개</li>
                                    </ul>
                                    <ul className="btn" onClick={() => setTicketCount(availableTickets)}>전체사용</ul>
                                </div>
                                <div className="all">
                                    <Checkbox onChange={handleAlwaysUseAllChange} checked={alwaysUseAll}>항상 전체사용</Checkbox>
                                </div>
                                <div className="bntVote" onClick={handleVote}>투표하기</div>
                            </div>
                        ) : (
                            <div className="voteResult">
                                <div className="title">투표가 완료되었습니다.</div>
                                <div className="txt">내 투표권<span>{ticketCount}개</span>로 <span>김고은</span>에게<br/><span>0.005%</span> 기여했어요.</div>
                                <div className="list">
                                    <ul>
                                        <li className="rank">20</li>
                                        <li className="thumb"><img src="https://i.namu.wiki/i/CeGNodsZyVA1Yi1ar-zux5wBQCHCn-sJBi0xFqpxJU_xj5KHuNl8Hg35cVuXw56VbBWcVZnlYfkAzqoCYRUAGw.webp" alt="" /></li>
                                        <li className="info">
                                            <dl className="cate">가수<div className="dot"></div>여성</dl>
                                            <dl className="name">현아</dl>
                                        </li>
                                        <li className="score">667,539 점</li>
                                    </ul>
                                    <ul className="on">
                                        <li className="rank">21</li>
                                        <li className="thumb"><img src="https://i.namu.wiki/i/BTFUZ_0TugZ15vrrsRKMVEJoqKGOF_jD-aw5sGeZZJnd3OIGWCcoVzaJ3YHBusb_msDaT_fnWqa5KR6APsT-uQ.webp" alt="" /></li>
                                        <li className="info">
                                            <dl className="cate">배우</dl>
                                            <dl className="name">김고은</dl>
                                        </li>
                                        <li className="score">920,008 점</li>
                                    </ul>
                                    <ul>
                                        <li className="rank">22</li>
                                        <li className="thumb"><img src="https://images.khan.co.kr/article/2021/11/15/l_2021111502000877900178361.jpg" alt="" /></li>
                                        <li className="info">
                                            <dl className="cate">가수<div className="dot"></div>여성</dl>
                                            <dl className="name">안유진</dl>
                                        </li>
                                        <li className="score">907,884 점</li>
                                    </ul>
                                </div>
                                <div className="btnBox">
                                    <span  onClick={() => {
                                        navigate(-1); // -1을 전달하여 이전 페이지로 이동
                                    }}>
                                        <ul className="back">돌아가기</ul>
                                    </span>
                                    <Link to="/vote/report"><ul className="report">트렌드 리포트 보기</ul></Link>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </>
    );
}

export default DoVote;
