import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function RankingNewsBox({ rankingData }) {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isPaused, setIsPaused] = useState(false); // 롤링이 멈췄는지 여부

    useEffect(() => {
        // 롤링 기능을 설정
        const interval = setInterval(() => {
            if (!isPaused) {
                setCurrentIndex(prevIndex => (prevIndex + 1) % rankingData.rankItems.length);
            }
        }, 2000); // 2초마다 인덱스를 변경합니다.

        return () => clearInterval(interval); // 컴포넌트가 언마운트 될 때 클리어
    }, [isPaused, rankingData.rankItems.length]);

    const handleMouseEnter = () => {
        setIsPaused(true); // 마우스가 올라가면 롤링 멈춤
    };

    const handleMouseLeave = () => {
        setIsPaused(false); // 마우스가 벗어나면 롤링 재시작
    };

    return (
        <div className="rankBox" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div className="rankTitle">{rankingData.title}<div className="arw s24"></div></div>
            <div className="box">
                <div className="rank">
                    {rankingData.rankItems.map((item, index) => (
                        <ul key={item.rank} className={currentIndex === index ? 'on' : (currentIndex === (index + 1) % rankingData.rankItems.length ? 'off' : '')}>
                            <li className="num">{item.rank}</li>
                            <li className="thumb">
                                <img src={item.imageUrl} alt={item.title} />
                            </li>
                            <li className="title">{item.title}</li>
                            <li className="vote">{item.votes}</li>
                        </ul>
                    ))}
                </div>
                <div className="news">
                    {rankingData.newsItems.map((item, index) => (
                        <Link to={item.link} key={item.rank}>
                            <ul className={currentIndex === index ? 'on hoverImgPt' : 'off hoverImgPt'}>
                                <li className="num">{item.rank}</li>
                                <li className="title">{item.title}</li>
                                <li className="thumb">
                                    <img src={item.imageUrl} alt={item.title} />
                                </li>
                            </ul>
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default RankingNewsBox;
