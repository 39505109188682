import {Link, useNavigate} from "react-router-dom";
import React, {useCallback, useEffect, useState} from "react";
import StickyDefault from "../layout/stickyDefault";
import NewsListRanking from "./newsListRanking";
import {Pagination} from "antd";
import { useParams } from 'react-router-dom';
import {useApi} from "../../js/module/api";
import decodeHtmlEntities from "../../js/module/HtmlDecode";
import htmlToText from "../../js/module/HtmlToText";

function Contents() {
    const [height, setHeight] = useState('2100px');
    const [itemHeight, setItemHeight] = useState(175);
    const apiUrl = process.env.REACT_APP_API_URL;
    const { categoryId, pageNumber } = useParams();
    const navigate = useNavigate();
    const [contentsList, setContentsList] = useState([]);
    const [resentList, setResentList] = useState([]);
    const [popularsList, setPopularsList] = useState([]);
    const [currentPage, setCurrentPage] = useState(parseInt(pageNumber) || 1);
    const [totalItems, setTotalItems] = useState(0);
    const pageSize = 12;
    const recentNews = 4;
    const popularNews = 5;
    const { apiRequest } = useApi();

    const fetchNews = useCallback(async (page, categoryId) => {
        try {
            // categoryId에 따라 API 엔드포인트를 동적으로 설정
            const categoryMapping = {
                'issue': 'dc-issue',
                'interview': 'dc-interview',
                // 다른 카테고리가 있다면 여기에 추가
            };

            const categoryEndpoint = categoryMapping[categoryId] || 'dc-issue';

            const data = await apiRequest(
                `${apiUrl}api/v1/contents/${categoryEndpoint}?page=${page}&size=${pageSize}&recentNews=${recentNews}&popularNews=${popularNews}`,
                {},
                'GET'
            );

            const width = window.innerWidth;
            if (width < 480) {
                setItemHeight(169);
            } else if (width < 800) {
                setItemHeight(128);
            } else {
                setItemHeight(175); // 해상도가 700px 이상인 경우 기본값으로 설정
            }
            setHeight(data.data.contentsList.content.length * itemHeight + 'px');
            setContentsList(data.data.contentsList.content);
            setTotalItems(data.data.contentsList.metadata.totalCounts);
            setResentList(data.data.resents);
            setPopularsList(data.data.populars);
        } catch (error) {
            console.error("뉴스를 가져오는 데 실패했습니다.", error);
        }
    }, [apiRequest, pageSize, apiUrl, itemHeight]);

    useEffect(() => {
        const pageFromUrl = parseInt(pageNumber) || 1;
        setCurrentPage(pageFromUrl);
        fetchNews(pageFromUrl, categoryId);
    }, [categoryId, pageNumber, fetchNews]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
        navigate(`/news/${categoryId}/${page}`);
    };

    return (
        <>
            <StickyDefault/>
            <section className="contentsWrap">
                <div className="newsLayout">
                    <div className="leftWrap">
                        <div className="newsList" style={{height}}>
                            <div className="list">
                                {contentsList.map(item => (
                                    <ul key={item.id} className="hoverImgPt">
                                        <Link to={`/news/${categoryId}/detail/${item.id}`}>
                                            <div className="thumb skeletonStyle">
                                                <img
                                                    src={item.thumbnail || "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage.svg"}
                                                    alt={item.title || 'No Image'}
                                                    onError={(e) => { e.target.src = "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage.svg"; }}
                                                />
                                            </div>
                                            <li className="tit">{decodeHtmlEntities(item.title)}</li>
                                            <li className="txt">{htmlToText(item.content, 150)}</li>
                                            <li className="info">{item.pressName}</li>
                                        </Link>
                                    </ul>
                                ))}
                            </div>
                            <div className="paging">
                                <Pagination
                                    onChange={handlePageChange}
                                    align="center"
                                    defaultCurrent={1}
                                    total={totalItems}
                                    pageSize={pageSize}
                                    current={currentPage}
                                    showSizeChanger={false}
                                    responsive={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="rightWrap">
                        <div className="rightSticky">
                            <div className="stickyTitle">랭킹 뉴스</div>
                            <div className="rankNews mgb40">
                                <div className="rankChartRight">
                                    <NewsListRanking />
                                </div>
                            </div>
                            <div className="stickyTitle">실시간 인기기사</div>
                            <div className="popularNewsRight">
                                {popularsList.map((item,index) => (
                                    <Link key={index} to={`/news/trend/detail/${item.categoryId}/${item.newsId}`}>
                                        <ul key={index}>
                                            <li>{index + 1}</li>
                                            <li>{decodeHtmlEntities(item.title)}</li>
                                            <li>
                                                <img
                                                    src={item.thumbnail || "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage.svg"}
                                                    alt={item.title || 'No Image'}
                                                    onError={(e) => { e.target.src = "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage.svg"; }}
                                                />
                                            </li>
                                        </ul>
                                    </Link>
                                ))}
                            </div>
                            <div className="stickyTitle">최신 기사</div>
                            <div className="rtNewsRight">
                                {resentList.map(item => (
                                    <ul key={item.newsId}>
                                        <Link to={`/news/trend/detail/${item.categoryId}/${item.newsId}`}>
                                            <li>
                                                <img
                                                    src={item.thumbnail || "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage.svg"}
                                                    alt={item.title || 'No Image'}
                                                    onError={(e) => { e.target.src = "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage.svg"; }}
                                                />
                                            </li>
                                            <li>{decodeHtmlEntities(item.title)}</li>
                                        </Link>
                                    </ul>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Contents;